// this is the place for your variable overrides, paste variables from custom/_variables.scss here and set your values

// primary colour is set to false by default to enable multiple colour variants stylesheet creation, 
// change to your brand colour if needed
$primary: #4E66F8;
$secondary: #6c757d;

// This is here just as an example, uncomment the lines here and see it in action 
// $primary:       #ef5285; 
// $secondary:     #429244 !default;
// $font-family-sans-serif:        -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// $font-family-heading:           -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;