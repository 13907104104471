// this is the place for your components / CSS
body {
    padding-top: 72px;
}

.vert-menu {
    .nav-link {
        &.active {
            font-weight: bold;
        }
    }
}

.provider-detail{
    .gallery-container {
        position: relative;
        cursor:pointer;

        .text {
            position: absolute;
            bottom: 20px;
            right: 20px;
            font-family: "Proxima Nova Semi Bold", Arial, sans-serif;
            font-size: 13px;
            color: #F5F7F9 !important;
            text-align: center;
            background-color: rgba(44, 45, 48, 0.6);
            padding: 5px 10px;
            border-radius: 3px;
            transition: 0.3s;
            cursor:pointer;
          }
      }
    
     
}

.provider-group-detail {
    .provider-group-item {
        margin-top: 2rem;
        padding-bottom: 1rem;
        border-bottom: solid 1px #eee;
        &:last-child {
            border-bottom: none;
        }
    }
}
